import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import { css } from '@emotion/core';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>Traduction</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">Traduction</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <p>
                  Ce blog est un mélange de deux langues et je voulais clarifier un point sur les
                  traductions lorsque les articles sont dans les deux langues. Je ne fais pas de
                  traductions au sens strict du terme car je prends de grandes libertés entre les
                  deux versions et c’est volontaire. D’une part c’est plus fun pour moi et d’autre
                  part il y a des références qui fonctionnent d’avantage pour un langage ou un pays
                  que l’autre.
                </p>
                <p>
                  Pour tous les articles en français <Link to="/tags/french">cliques ici</Link>
                </p>
                <p>
                  This blog is a mix of two languages and I wanted to clarify one point regarding
                  translations when post are both in French and in English. I don’t do direct
                  translations, I take a lot of liberties between both versions because it is nicer
                  for me and some references work better in one language/country than the other.
                </p>
                {/* <p>For English posts only click here.</p> */}
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
